/* Profile Section Styling */

/* Light Mode */
.profile-card {
    background: #e0e0e0;
    border-radius: 12px;
    box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
    padding: 2px;
    margin-bottom: 20px;
  
  }
  
  /* Dark Mode */
  body.dark-mode .profile-card {
    background: #2c2c2e;
    box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  }
  
  /* Profile Info */
  .profile-info {
    display: flex;
    align-items: center;
  }
  
  .profile-info img {
    border-radius: 50%;
   
  }

  .profile-info img {
    border-radius: 50%;
   
  }

  .profile-image{
    margin-right: 10px;
    border-radius: 50%;
margin-left: 10px;
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
  }
  
  
  


  .profile-info h2 {
    font-size: 14px;
    color: #202124;
    margin-right: 20px;
  }
  
  body.dark-mode .profile-info h2 {
    color: #e8eaed;
  }
  
  /* Logout Button */
  .logout-button {
    
    background: #e0e0e0;
    color: #202124;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 12px;
    transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
    box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
    margin-left: auto;
  }
  
  body.dark-mode .logout-button {
    background: #2c2c2e;
    color: #e8eaed;
    box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  }
  
  .logout-button:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
  }
  
  body.dark-mode .logout-button:hover {
    box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
  }
  
  .logout-icon {
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */

  }
  
  
  
  body.dark-mode .logout-icon {
    filter: invert(1);
  }
  
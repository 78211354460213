.session-log-viewer-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    pointer-events: none;
  }
  
  .session-log-viewer {
    background: #e0e0e0;
    border-radius: 12px;
    width: 80vw;
    height: 80vh;
    box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    resize: both;
    overflow: auto;
    pointer-events: all;
    min-width: 675px;
    min-height: 100px;
  }
  
  body.dark-mode .session-log-viewer {
    background: #2c2c2e;
    box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  }
  
  .session-log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 20px;
    cursor: move;
    background: #e0e0e0;
    padding: 10px;
  }
  
  body.dark-mode .session-log-header {
    background: #2c2c2e;

  }
  
  .session-close-button {
    background: #e0e0e0;
    color: #202124;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
    box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  }
  
  body.dark-mode .session-close-button {
    background: #2c2c2e;
    color: #e8eaed;
    box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  }
  
  .session-close-button:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
  }
  
  body.dark-mode .session-close-button:hover {
    box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
  }
  
  .session-log-title {
    font-size: 13px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
  }
  
  .site-selector-dropdown {
    position: relative;
    display: inline-block;
margin-top:4px;
  }
  
  .site-selector-header {
    padding: 4px;
    border-radius: 8px;
    background-color: #e0e0e0;
    color: #202124;
    box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    max-height: 30px;
    min-width: 100px;
    font-weight: bold;
  }
  
  body.dark-mode .site-selector-header {
    background-color: #2c2c2e;
    color: #e8eaed;
    box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
  }
  
  .site-selector-arrow {
    font-size: 18px;
   
  }
  
  .site-selector-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #e0e0e0;
    color: #202124;
    border-radius: 8px;
    box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
    margin-top: 4px;
    z-index: 10;
    font-size: 12px;
    max-height: 200px;
    overflow-y: auto;
    text-align: left;
 
  }
  
  body.dark-mode .site-selector-list {
    background-color: #2c2c2e;
    color: #e8eaed;
    box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
    cursor: pointer
  }
  
  .site-selector-option {
    padding: 8px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  }
  
  .site-selector-option:hover {
    background-color: #c1c1c1;
  }
  
  body.dark-mode .site-selector-option:hover {
    background-color: #444444;
  }
  
  .site-selector-option:not(:last-child) {
    border-bottom: 1px solid #bebebe;
  }
  
  body.dark-mode .site-selector-option:not(:last-child) {
    border-bottom: 1px solid #3e3e3e;
  }
  

  /* Ensure no styling for .draggable-corner */
  .draggable-corner {
    display: none;
  }

  .react-resizable-handle {
    display: none;
  }

@keyframes loader_5191 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader {
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader_svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  
#loader_sq1 {
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#loader_sq2 {
  top: calc(50% - 30px);
  left: 50%;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#loader_sq3 {
  top: calc(50% - 30px);
  left: calc(50% + 30px);
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#loader_sq4 {
  top: 50%;
  left: calc(50% - 30px);
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#loader_sq5 {
  top: 50%;
  left: 50%;
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#loader_sq6 {
  top: 50%;
  left: calc(50% + 30px);
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#loader_sq7 {
  top: calc(50% + 30px);
  left: calc(50% - 30px);
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#loader_sq8 {
  top: calc(50% + 30px);
  left: 50%;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#loader_sq9 {
  top: calc(50% + 30px);
  left: calc(50% + 30px);
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}

  .session-new-tab-button {
    background: #e0e0e0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
    box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
  
  body.dark-mode .session-new-tab-button {
    background: #2c2c2e;
    color: #e8eaed;
    box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  }
  
  .session-new-tab-button:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
  }
  
  body.dark-mode .session-new-tab-button:hover {
    box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
  }
  
  .open-tab-icon {
    width: 16px;
    height: 16px;
    filter: invert(0%);
    padding: 2px;

  }
  
  body.dark-mode .open-tab-icon {
    filter: invert(100%);
  }
  
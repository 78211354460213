/* SVG pattern styling */
.pattern-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3; /* Ensure SVGs are behind the main content */
  pointer-events: none; /* Ensure SVGs do not interfere with user interactions */
}

.pattern-item {
  position: absolute;
  filter: invert(1);
  opacity: 0.1; /* Adjust opacity as needed */
}

.pattern-item img {
  width: 100%; /* Make the image size responsive */
  height: 100%;
}




/* Fun animation combining bounce, scale, and rotation */
@keyframes funBounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) scale(1) rotate(0deg);
  }
  40% {
    transform: translateY(-30px) scale(1.1) rotate(-10deg);
  }
  60% {
    transform: translateY(-15px) scale(1.05) rotate(5deg);
  }
}



.fun-bounce {
  animation: funBounce 1s ease;
}

.landing-page-logo:hover {
  animation: funBounce 1s ease;
}


.social-section {
  margin-top: 30px;
  text-align: center;
}

.social-section-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


.social-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 20px;
}
.social-button {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1); /* Glassmorphic background */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border */
  transition: all 0.45s;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px); /* Apply blur effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 255, 255, 0.2); /* Soft shadows */
}

.social-button:hover {
  background: rgba(255, 255, 255, 0.2); /* Slightly brighter on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 0 15px rgba(255, 255, 255, 0.3); /* Enhanced shadow on hover */
  color: #2d2e32;
}

.social-button:hover .btn-svg {
  transform: rotate(360deg);
  transform-origin: center center;
  transition: transform 0.45s ease-in-out;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg)
    brightness(103%) contrast(103%);
}

.social-button .btn-svg {
  width: 22px;
  height: 22px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}








/* Background animation for the landing page */
.landing-page-background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #2c2c2e, #3a3a3c, #444446, #1a1a1c, #1e1e20, #3e3e42, #2e2e30);
  background-size: 600% 600%;
  animation: landingPageGradientAnimation 15s ease infinite;
  z-index: 2;
}

@keyframes landingPageGradientAnimation {
  0% { background-position: 0% 50%; }
  25% { background-position: 50% 100%; }
  50% { background-position: 100% 50%; }
  75% { background-position: 50% 0%; }
  100% { background-position: 0% 50%; }
}



/* Custom Toast for Landing Page */
/* Custom Toast for Landing Page */
.landing-toast {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  color: #fff;
  border-radius: 15px;
  padding: 15px;
  font-size: 16px;
}

.landing-toast-info {
  border-left: 4px solid rgba(74, 144, 226, 0.6);
}

.landing-toast-success {
  border-left: 4px solid rgba(76, 175, 80, 0.6);
}

.landing-toast-error {
  border-left: 4px solid rgba(244, 67, 54, 0.6);
}

.landing-toast-progress {
  background: rgba(255, 255, 255, 0.2);
}

/* Adjust the toast progress bar for glassmorphic effect */
.landing-toast .Toastify__progress-bar {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5));
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), -2px -2px 4px rgba(255, 255, 255, 0.3);
}


.google-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px; /* Adjust the spacing as needed */
  vertical-align: middle;
}

/* Landing page specific styles */
.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Webkit browsers */
.landing-page::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}



.landing-page-content {
  text-align: center;
  position: relative;
  z-index: 2;
  padding: 20px;
}

.landing-page-main-title {
  font-size: 2.5rem;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.landing-page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust spacing as needed */
  margin-top: 50px;
}



.landing-page-logo {
  width: 50px; /* Adjust size as needed */
  height: auto;
  transition: transform 1s ease;

}

.landing-page-tagline {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #ddd;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin-top: 0;
}

.landing-page-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.landing-page .landing-page-login-option {
  margin: 0px 0;
}

.landing-page .landing-page-login-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.landing-page .landing-page-styled-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 1.2rem;
  margin: 10px 0;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  color: #fff;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.landing-page .landing-page-styled-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.landing-page .landing-page-or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-size: 1rem;
  opacity: 0.7;
  color: #fff;
  z-index: 3;
  position: relative;
  width: 50%;
}


.landing-page .landing-page-or-divider::before,
.landing-page .landing-page-or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 0.1px solid rgba(255, 255, 255, 0.5);
  margin: 0 10px;
  z-index: 3;
}
/* Global Styles */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #e0e0e0;
  color: #202124;
  transition: background-color 0.3s, color 0.3s;
}


body.dark-mode, html.dark-mode {
  background-color: #2c2c2e;
  color: #e8eaed;
}


/* App Component */
.App {
  text-align: center;
  padding: 20px;
  background-color: #e0e0e0;
  color: #202124;
  min-height: 100%;
  border-radius: 12px;
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode .App {
  background-color: #2c2c2e;
  color: #e8eaed;
}


.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #e0e0e0;
  border-bottom: 1px solid #ccc;
}



body.dark-mode .app-header {
  background-color: #2c2c2e;
  border-bottom: 1px solid #444;
}

.logo-container {
  display: flex;
  align-items: center;
}

.specter-logo {
  height: 40px; /* Adjust the height as needed */
}


/* Neumorphism Styles */
.neu-morphic {
  background: #e0e0e0;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .neu-morphic {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.google-signin-wrapper {
  position: relative;
  width: 200px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  margin: 0 auto; /* Center the button */
}

.google-signin-wrapper img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.google-signin-wrapper button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
}

.dev-url-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it takes full width */
  margin: 20px 0; /* Add some vertical spacing */
}

.dev-url-container label {
  text-align: center;
}

.dev-url-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px; /* Adjust width as needed */
}

.dev-url-wrapper input {
  width: 100%;
  padding: 12px 20px !important; /* Add padding for the icon */
  padding-left: 40px !important; /* Ensure padding-left provides enough space for the icon */
  width: 280px!important;; /* Adjust this value as needed */
  margin-top: 10px; /* Add some space above the input */
  border: none;
  border-radius: 12px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}

body.dark-mode .dev-url-wrapper input {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

.dev-url-icon {
  position: absolute;
  top: 50%;
  left: 12px; /* Adjust position as needed */
  transform: translateY(-50%);
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
}

body.dark-mode .dev-url-icon {
  filter: invert(1); /* Ensure icon is visible in dark mode */
}


.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  max-width: 450px; /* Adjust max-width as needed */
  margin: 20px auto; /* Center horizontally */
  font-size: 16px;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

body.dark-mode .or-divider::before,
body.dark-mode .or-divider::after {
  border-color: #444;
}

.or-divider:not(:empty)::before {
  margin-right: .25em;
}

.or-divider:not(:empty)::after {
  margin-left: .25em;
}



.test-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  margin: 20px auto; /* Center horizontally */
  font-size: 16px;
}

.test-divider::before,
.test-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

body.dark-mode .test-divider::before,
body.dark-mode .test-divider::after {
  border-color: #444;
}

.test-divider:not(:empty)::before {
  margin-right: .25em;
}

.test-divider:not(:empty)::after {
  margin-left: .25em;
}

.not-a-member {
  margin-top: 20px;
  font-size: 14px;
  color: #1f1d1d;
}

body.dark-mode .not-a-member {
  color: #ccc;
}

.not-a-member a {
  color: #4a90e2;
  text-decoration: none;
}

body.dark-mode .not-a-member a {
  color: #8ab4f8;
}

.not-a-member a:hover {
  text-decoration: underline;
}

body.dark-mode .not-a-member a:hover {
  text-decoration: underline;
}



.then-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  max-width: 650px; /* Adjust max-width as needed */
  margin: 20px auto; /* Center horizontally */
  font-size: 16px;
}

.then-divider::before,
.then-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

body.dark-mode .then-divider::before,
body.dark-mode .then-divider::after {
  border-color: #444;
}

.then-divider:not(:empty)::before {
  margin-right: .25em;
}

.then-divider:not(:empty)::after {
  margin-left: .25em;
}

.embedded-site-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin-left: auto; /* Adjust as needed for spacing */
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;

}

.embedded-site-icon {
  width: 18px; /* Adjust size as needed */
  height: 18px; /* Adjust size as needed */
}



.env-label-container {
  display: flex;
  align-items: center;
 
  padding: 1px;
  padding-left: 20px;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;

  width: 20%; /* Adjust the width as needed */
  max-width: 1200px;
  transition: background-color 0.3s, color 0.3s;
  margin-right: auto;
}

body.dark-mode .env-label-container {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.env-label {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;

}

.end-session-button {
  padding: 6px;
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
 
  display: flex;
  align-items: center;
  justify-content: center;
}

body.dark-mode .end-session-button {
  background: #2c2c2e;
  color: #e8eaed;
 
}

.end-session-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .end-session-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}


.end-session-icon {
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
}



body.dark-mode .end-session-icon {
  filter: invert(1);
}


.env-icon {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  margin-right: 8px; /* Space between icon and text */
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}


.member-org-info {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  padding: 1px;
  padding-left: 20px;
}

.member-icon, .org-icon {
  width: 30px; /* Ensures the image is always 30px wide */
  height: 30px; /* Ensures the image is always 30px tall */
  max-width: 30px; /* Prevents the image from being wider than 30px */
  min-width: 30px; /* Prevents the image from being narrower than 30px */
  border-radius: 6px; /* Rounded corners */
  margin-right: 10px; /* Space between the icon and the name */
  vertical-align: middle; /* Aligns the icon with the text */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}


.separator {
  margin: 0 10px; /* Space around the separator */
  border-left: 1px solid #cccccc; /* Separator style */
  height: 20px; /* Adjust height as needed */
}

body.dark-mode .member-org-info {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.member-name, .org-name {
  font-size: 14px;
  font-weight: 500;
}

body.dark-mode .member-icon, 
body.dark-mode .org-icon {
  filter: none; /* Prevents color inversion for actual images */
}

.default-icon {
  filter: invert(1); /* Invert colors for placeholder icons in dark mode */
}

.end-session-button {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
}


/* Form Elements */
label {
  display: block;
  margin: 10px 0;
  font-size: 14px;
  color: #5f6368;
  font-weight: bold;
}

body.dark-mode label {
  color: #bdc1c6;
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"], textarea, select {
  padding: 12px;
  margin: 5px 0;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  width: 100%;
  max-width: 280px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
}

body.dark-mode input[type="text"], 
body.dark-mode input[type="email"], 
body.dark-mode input[type="password"], 
body.dark-mode input[type="number"], 
body.dark-mode textarea, 
body.dark-mode select {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

/* Neomorphic focus style */
input:focus, select:focus, textarea:focus {
  outline: none;
  box-shadow: inset 4px 4px 8px #c1c1c1, inset -4px -4px 8px #f5f5f5;
  background-color: #e5e5e5;
}

body.dark-mode input:focus, 
body.dark-mode select:focus, 
body.dark-mode textarea:focus {
  box-shadow: inset 4px 4px 8px #1f1f1f, inset -4px -4px 8px #3b3b3b;
  background-color: #333333;
  color: #e8eaed;
}



.password-input-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  margin: 0 auto; /* Add this line to center the container */
}

.password-input {
  width: 100%;
  padding-right: 40px; /* Adjust based on icon size */
  
}

.password-toggle-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0; /* Remove padding */
  width: 20px; /* Adjust width to match icon size */
  height: 20px; /* Adjust height to match icon size */
}

.password-toggle-button img {
  width: 100%;
  height: 100%;
  filter: brightness(0) saturate(100%); /* Black in light mode */
  transition: filter 0.3s;
}

body.dark-mode .password-toggle-button img {
  filter: invert(1) brightness(2); /* White in dark mode */
}















/* Player Fields */
.player-fields {
  padding: 20px;
  margin: 10px 0;
  border-radius: 12px;
  background-color: #e0e0e0;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
}

body.dark-mode .player-fields {
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

/* Buttons */
button {
  padding: 10px 20px;
  margin: 10px 5px;
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}



button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

body.dark-mode button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

/* Tabs */
.tabs {

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 10px;
  white-space: nowrap;
  padding: 10px 0; /* Add some padding to ensure tabs are not cut off */
}

.tab-button {
  padding: 10px 20px;
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .tab-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.tab-button.active {
  background: #c8c8c8;
  color: #202124;
}

body.dark-mode .tab-button.active {
  background: #404040;
  color: #e8eaed;
}

.tab-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .tab-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.tabs.center {
  justify-content: center;
}

/* Headings */
h1, h2, h3, h4 {
  color: #202124;
  font-weight: 500;
 
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode h4 {
  color: #e8eaed;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  padding: 5px;
}

h2 {
  font-size: 20px;
  font-weight: 800;
  padding: 5px;
}

h3 {
  font-size: 18px;
  font-weight: 200;
  padding: 3px;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  padding: 5px;
}

/* Custom Parameter and Inventory Rows */
.custom-param, .inventory-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; /* Allow wrapping to prevent overlapping */

}
.inventory-row {
gap: 10px;
}


.custom-param label, .inventory-row label {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 1; /* Allow the fields to take available space */
  min-width: 150px; /* Ensure a minimum width to prevent collapsing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
 
}

.custom-param input, .inventory-row input, .custom-param select, .inventory-row select {
  margin-top: 5px; /* Add margin to create space between label text and input/select */
  padding: 8px; /* Add padding for better spacing */
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  margin-right: 20px;
  display: flex;
  flex: 1;
  
}

.custom-param{
  margin-left: 180px;
}

.custom-param button, .inventory-row button {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  background: #e0e0e0;
  color: #202124;
 
  margin-left: 10px;
}

.inventory-row button{
  margin-top: 24px;
}


body.dark-mode .custom-param button, body.dark-mode .inventory-row button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.custom-param button:hover, .inventory-row button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .custom-param button:hover, body.dark-mode .inventory-row button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}


.end-session-button, .play-match-button, .play-competition-button, .manage-inventory-button {
  margin: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top of other elements */
}

body.modal-open {
  overflow: hidden; /* Ensure that other content on the page cannot be interacted with */
}

/* Popup Styles */


/* Popup Styles */
.profile-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e0e0e0;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  z-index: 1000;
  width: 35%; /* Fixed width */
 height: 80%; /* Fixed height */
  overflow-y: auto;
  transition: background 0.3s, transform 0.2s;
   padding: 20px;
  /* display: flexbox; */
}

body.dark-mode .profile-popup {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.profile-popup h2 {
  margin-top: 0px;
}
/* Ensure the popup is properly centered and expands to full screen */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e0e0e0;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  z-index: 1000;
  width: 75%; /* Adjust the width as needed */
  height: 92%; /* Adjust the height as needed */
  overflow-y: auto;
  transition: background 0.3s, transform 0.2s;
  display: flex;
  flex-direction: column;
  transition: background 0.3s, transform 0.2s, width 0.5s ease, height 0.5s ease; /* Added transition for width and height */
}

body.dark-mode .popup {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.popup.full-screen {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: none;
  border-radius: 0;
  transition: width 0.5s ease, height 0.5s ease; /* Added transition for width and height */
}


/* Adjust header to stay sticky at the top */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-bottom: 0.5px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
  background: inherit; /* Inherit background to blend with popup */
}

body.dark-mode .popup-header {
  border-bottom: 0.5px solid #444; /* Dark mode border color */
  background: rgba(44, 44, 46, 0.8); /* Dark mode background color */
}

.popup-header h2 {
  margin: 0;
}


.popup-content {
  flex: 1;
  padding: 1em;
  overflow-y: auto; /* Enable vertical scrolling */
}

.popup-content.full-screen-content {
  padding: 1em;
  overflow-y: auto;
}

.custom-params-popup input[type="text"], .inventory-popup input[type="text"], .inventory-popup input[type="number"], .custom-params-popup select, .inventory-popup select {
  width: 150px;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
}

body.dark-mode .custom-params-popup input[type="text"], 
body.dark-mode .inventory-popup input[type="text"], 
body.dark-mode .inventory-popup input[type="number"], 
body.dark-mode .custom-params-popup select, 
body.dark-mode .inventory-popup select {
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}
/* Toggle Switch Styles */
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  margin-left: 50px;
}

.toggle-option {
  padding: 10px 22px;
  cursor: pointer;
  color: #5f6368;
  font-size: 14px;
  transition: color 0.3s, transform 0.2s;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-option img {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-right: 8px; /* Add margin to space out the icon and text */
}


.toggle-option.active {
  color: #000000;
  
}

body.dark-mode .toggle-option.active {
  filter: invert(1) brightness(200%); /* Ensure image appears white in dark mode */
}



.toggle-option.active img {
  filter: invert(0); /* Full color for active state */
}

.toggle-option:not(.active) img {
  filter: none; /* Greyed out for inactive state */
}


body.dark-mode .toggle-option.active img {
  color: white; /* White color for the active state in dark mode */
}

.toggle-switch {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  background: rgba(218, 220, 224, 0.8);
  border-radius: 25px; /* Adjust the border-radius proportionally */
  transform: translate(-50%, -50%);
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px; /* Adjust the padding as needed */
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .toggle-switch {
  background: rgba(60, 64, 67, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.toggle-switch::after {
  content: '';
  position: absolute;
  top: 2.5px;
  left: 2px;
  width: 115px; /* Adjust the width of the knob */
  height: 45px; /* Adjust the height of the knob */
  background-color: #fff;
  border-radius: 25px; /* Adjust the border-radius proportionally */
  transition: transform 0.3s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}


.toggle-switch.dark::after {
  transform: translateX(100px); /* Adjust as needed */
  background-color: #333; /* Dark background for the switch in dark mode */
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e; /* Adjust shadow for dark mode */
}

.toggle-switch.light::after {
  transform: translateX(0);
}

.switch-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}


/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Disabled state */
input:disabled + .slider {
  background-color: #ddd;
  cursor: not-allowed;
}


/* New Select Table Styles */
table.new-select-table {
  width: auto;
  max-width: 90%;
  border-collapse: collapse;
  margin: 20px auto;
  font-size: 14px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

.new-select-table thead {
  background-color: #f2f2f2;
}

.new-select-table th, .new-select-table td {
  border: none;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}

.new-select-table th {
  font-weight: bold;
}

body.dark-mode .new-select-table {
  border-color: #444;
}

body.dark-mode .new-select-table thead {
  background-color: #333;
  color: #fff;
}

body.dark-mode .new-select-table th, 
body.dark-mode .new-select-table td {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body.dark-mode .new-select-table tr:nth-child(even) {
  background-color: #444;
}

body.dark-mode .new-select-table tr:hover {
  background-color: #555;
}

.new-select-table input[type="number"] {
  width: 100%; /* Ensure input takes full width of the cell */
  padding: 8px;
  margin: 0;
  box-sizing: border-box; /* Ensure padding and border are included in width and height */
  border: none;
  border-radius: 8px;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
}

.new-select-table input[type="number"]:focus {
  outline: none;
  background-color: #e5e5e5;
  box-shadow: inset 4px 4px 8px #c1c1c1, inset -4px -4px 8px #f5f5f5;
}

body.dark-mode .new-select-table input[type="number"] {
  background-color: #2c2c2e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body.dark-mode .new-select-table input[type="number"]:focus {
  background-color: #333333;
  box-shadow: inset 4px 4px 8px #1f1f1f, inset -4px -4px 8px #3b3b3b;
}

/* Table Styles */
table {
  width: 70%;
  border-collapse: collapse;
  margin: 20px auto;
  font-size: 14px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

thead {
  background-color: #f2f2f2;
}

th, td {
  border: none;
  padding: 12px;
  text-align: center;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}

th {
  font-weight: bold;
}

body.dark-mode table {
  border-color: #444;
}

body.dark-mode thead {
  background-color: #333;
  color: #fff;
}

body.dark-mode th, body.dark-mode td {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body.dark-mode tr:nth-child(even) {
  background-color: #444;
}

body.dark-mode tr:hover {
  background-color: #555;
}

table input[type="checkbox"],
label > input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 8px;
  vertical-align: middle;
  border: none;
  border-radius: 4px;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
  appearance: none; /* Remove default styles */
}

table input[type="checkbox"]:checked,
label > input[type="checkbox"]:checked {
  background-color: #c1c1c1; /* Lighter background for checked state */
  box-shadow: inset 2px 2px 4px #a0a0a0, inset -2px -2px 4px #ffffff;
}

table input[type="checkbox"]:focus,
label > input[type="checkbox"]:focus {
  outline: none;
  background-color: #e5e5e5;
  box-shadow: inset 4px 4px 8px #c1c1c1, inset -4px -4px 8px #f5f5f5;
}

body.dark-mode table input[type="checkbox"],
body.dark-mode label > input[type="checkbox"] {
  background-color: #2c2c2e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body.dark-mode table input[type="checkbox"]:checked,
body.dark-mode label > input[type="checkbox"]:checked {
  background-color: #444444; /* Lighter background for checked state in dark mode */
  box-shadow: inset 2px 2px 4px #3a3a3a, inset -2px -2px 4px #5c5c5c;
}

body.dark-mode table input[type="checkbox"]:focus,
body.dark-mode label > input[type="checkbox"]:focus {
  outline: none;
  background-color: #333333;
  box-shadow: inset 4px 4px 8px #1f1f1f, inset -4px -4px 8px #3b3b3b;
}

/* Checkbox Hover */
table input[type="checkbox"]:hover,
label > input[type="checkbox"]:hover {
  background-color: #d0d0d0;
}

body.dark-mode table input[type="checkbox"]:hover,
body.dark-mode label > input[type="checkbox"]:hover {
  background-color: #3a3a3c;
}

/* Checkbox Styles - Custom appearance for checked state */
table input[type="checkbox"]:checked::after,
label > input[type="checkbox"]:checked::after {
  content: '\2713'; /* Unicode for checkmark */
  color: #202124;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.dark-mode table input[type="checkbox"]:checked::after,
body.dark-mode label > input[type="checkbox"]:checked::after {
  color: #e8eaed;
}
/* Checkbox Styles */
label > input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 8px;
  vertical-align: middle;
  border: none;
  border-radius: 4px;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
  appearance: none; /* Remove default styles */
}

label > input[type="checkbox"]:checked {
  background-color: #c1c1c1; /* Lighter background for checked state */
  box-shadow: inset 2px 2px 4px #a0a0a0, inset -2px -2px 4px #ffffff;
}

label > input[type="checkbox"]:focus {
  outline: none;
  background-color: #e5e5e5;
  box-shadow: inset 4px 4px 8px #c1c1c1, inset -4px -4px 8px #f5f5f5;
}

body.dark-mode label > input[type="checkbox"] {
  background-color: #2c2c2e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body.dark-mode label > input[type="checkbox"]:checked {
  background-color: #444444; /* Lighter background for checked state in dark mode */
  box-shadow: inset 2px 2px 4px #3a3a3a, inset -2px -2px 4px #5c5c5c;
}

body.dark-mode label > input[type="checkbox"]:focus {
  outline: none;
  background-color: #333333;
  box-shadow: inset 4px 4px 8px #1f1f1f, inset -4px -4px 8px #3b3b3b;
}

/* Checkbox Hover */
label > input[type="checkbox"]:hover {
  background-color: #d0d0d0;
}

body.dark-mode label > input[type="checkbox"]:hover {
  background-color: #3a3a3c;
}

/* Checkbox Styles - Custom appearance for checked state */
label > input[type="checkbox"]:checked::after {
  content: '\2713'; /* Unicode for checkmark */
  color: #202124;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.dark-mode label > input[type="checkbox"]:checked::after {
  color: #e8eaed;
}

/* Ensure label is not clickable */
label.checkbox-label {
  pointer-events: none;
}
label.checkbox-label input[type="checkbox"] {
  pointer-events: auto;
}

.player-card {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Aligns items to the left and right */
  background: #e0e0e0;
  padding: 15px 25px;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  margin-bottom: 20px;
  
  margin: 20px auto;
  max-height: 100px;


}

body.dark-mode .player-card {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.player-icon img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 30px;
}

.player-info {
  flex-grow: 1.2; /* Allow player info to take up available space */
}

.player-info .player-name {
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
  display: flex;
  margin-left: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
  
}

body.dark-mode .player-info .player-name {
  color: #f3f4f6;
}


.player-id {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: #888;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 8px; /* Reduced margin */
  padding: 6px; /* Added padding to make the button smaller */
  display: flex;
  align-items: center;
}

.copy-button img {
  width: 16px;
  height: 16px;
}

.copy-success {
  font-size: 0.8em;
  margin-right: 8px;
}

body .copy-success {
  color: #2a7806;
}

body.dark-mode .copy-success {
  color: #53e111;
}


.view-button, .edit-button, .close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.icon {
  width: 24px;
  height: 24px;
}

.view-button img, .edit-button img, .close-button img {
  width: 18px;
  height: 18px;
}

/* Define light mode styles */
.view-button img,
 .edit-button img {
  filter: invert(0); /* Original color */
}

/* Define dark mode styles */
.dark-mode .view-button img,
.dark-mode .edit-button img {
  filter: invert(1); /* Inverted color */
}



.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-bottom: 0.5px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
  background: inherit; /* Inherit background to blend with popup */
}

.dark-mode .popup-header {
  border-bottom: 0.5px solid #444; /* Dark mode border color */
  background: rgba(44, 44, 46, 0.8); /* Dark mode background color */
}

.popup-header h1 {
  flex-grow: 1; /* Allow h2 to take available space */
  text-align: left; /* Center the text within the h2 */
  margin: 10px; /* Remove default margins */
  font-size: 20px;
}

.popup-header h2 {
  flex-grow: 1; /* Allow h2 to take available space */
  text-align: center; /* Center the text within the h2 */
  margin: 10px; /* Remove default margins */
}

.popup-content {
  flex: 1;
  padding: 1em;
  overflow-y: auto; /* Enable vertical scrolling */

}

.popup-content label {
  display: block;
  margin: 10px 0;
}

.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.profile-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #ccc;
  position: sticky;
}

.dark-mode .profile-popup-header  {
  border-bottom: 0.5px solid #444; /* Dark mode border color */
  background: rgba(44, 44, 46, 0.8); /* Dark mode background color */
}

.profile-popup-content label {
  display: block;
  margin: 10px 0;
}
.profile-popup-content img {
  display: block;
  margin: 10px auto; /* Center-align image */
  max-width: 250px;
  max-height: 250px;
  padding: 20px;
}

.profile-popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.close-button img {
  filter: brightness(0) saturate(100%);
}

.dark-mode .close-button img {
  filter: invert(1) brightness(2); /* Black color */
}



/* Neomorphic active state for edit button */
.edit-button.active {
  background: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}

body.dark-mode .edit-button.active {
  background: #2c2c2e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}



/* Style the select element */
.custom-select {
  padding: 12px;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"><path fill="%23202124" d="M5 6L0 0h10z"/></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 6px; /* Adjust arrow size */
}

body.dark-mode .custom-select {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"><path fill="%23e8eaed" d="M5 6L0 0h10z"/></svg>'); /* Custom arrow for dark mode */
  background-size: 10px 6px; /* Adjust arrow size */
}

.custom-select::-ms-expand {
  display: none; /* Remove default arrow in IE */
}

.custom-select:focus {
  outline: none;
  box-shadow: inset 2px 2px 4px #bebebe, inset -2px -2px 4px #ffffff;
}

body.dark-mode .custom-select:focus {
  box-shadow: inset 2px 2px 4px #1a1a1a, inset -2px -2px 4px #3e3e3e;
}

/* Style the dropdown options */
.custom-select option {
  background-color: #e0e0e0;
  color: #202124;
}

body.dark-mode .custom-select option {
  background-color: #2c2c2e;
  color: #e8eaed;
}

/* Extra styles to enhance the dropdown appearance */
.custom-select:active, .custom-select:hover {
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .custom-select:active, body.dark-mode .custom-select:hover {
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

/* Remove default list styling */
ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}


/* Card styles */
.card {
  background-color: #e0e0e0;
  padding: 20px;
  margin: 20px 0;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

body.dark-mode .card {
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .card:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.player-overall-card {
  background-color: #e0e0e0;
  padding: 20px;
  margin: 20px 0;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

body.dark-mode .player-overall-card {
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}




/* Radio Button Styles */
input[type="radio"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

input[type="radio"]:checked {
  background-color: #c1c1c1;
  box-shadow: inset 2px 2px 4px #a0a0a0, inset -2px -2px 4px #ffffff;
}

input[type="radio"]:focus {
  outline: none;
  background-color: #e5e5e5;
  box-shadow: inset 4px 4px 8px #c1c1c1, inset -4px -4px 8px #f5f5f5;
}

input[type="radio"]:checked::after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #202124;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.dark-mode input[type="radio"] {
  background-color: #2c2c2e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body.dark-mode input[type="radio"]:checked {
  background-color: #444444;
  box-shadow: inset 2px 2px 4px #3a3a3a, inset -2px -2px 4px #5c5c5c;
}

body.dark-mode input[type="radio"]:focus {
  box-shadow: inset 4px 4px 8px #1f1f1f, inset -4px -4px 8px #3b3b3b;
}

body.dark-mode input[type="radio"]:checked::after {
  background-color: #e8eaed;
}










/* Media Queries for Responsive Design */

/* Base font size and adjustments for larger screens */
body, html {
  font-size: 16px;
}

@media (min-width: 1200px) {
  body, html {
    font-size: 18px;
  }
}

/* Styles for devices with a width of 768px or less (tablets and small screens) */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .player-card, .card, .popup, .custom-params-popup, .inventory-popup {
    padding: 15px;
    margin: 10px auto;
    max-width: 100%;
  }

  .tabs {
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: visible;
  }

  .tab-button {
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
  }

  .custom-param, .inventory-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-param label, .inventory-row label {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  input[type="text"], input[type="email"], input[type="password"], input[type="number"], textarea, select,
  table input[type="text"], table input[type="email"], table input[type="password"], table input[type="number"], table textarea, table select {
    max-width: 100%;
    width: calc(100% - 16px); 
  }

  /* Adjusting font sizes */
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  table {
    width: 100%;
  }

  th, td {
    padding: 8px;
    font-size: 12px;
  }

  /* Adjusting input fields and text */
  .custom-param input, .inventory-row input, .custom-param select, .inventory-row select, table input, table select {
    max-width: calc(100% - 20px);
  }

  .player-info .player-name {
    font-size: 16px; /* Adjust font size for player name */
    word-wrap: break-word; /* Ensure text wraps to the next line */
  }
}

/* Styles for devices with a width of 480px or less (mobile phones) */
@media (max-width: 480px) {
  .App {
    padding: 5px;
  }

  .player-card, .card, .popup, .custom-params-popup, .inventory-popup {
    padding: 10px;
    margin: 5px auto;
    max-width: 100%;
  }

  .tabs {
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: visible;
  }

  .tab-button {
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
  }

  .custom-param, .inventory-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-param label, .inventory-row label {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }

  input[type="text"], input[type="email"], input[type="password"], input[type="number"], textarea, select,
  table input[type="text"], table input[type="email"], table input[type="password"], table input[type="number"], table textarea, table select {
    max-width: 100%;
    width: calc(100% - 20px); /* Adjust width to fit within the container */
  }

  /* Further adjusting font sizes */
  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 12px;
  }

  table {
    width: 100%;
  }

  th, td {
    padding: 6px;
    font-size: 10px;
  }

  /* Adjusting input fields and text */
  .custom-param input, .inventory-row input, .custom-param select, .inventory-row select, table input, table select {
    max-width: calc(100% - 20px);
  }

  .player-info .player-name {
    font-size: 14px; /* Adjust font size for player name */
    word-wrap: break-word; /* Ensure text wraps to the next line */
  }
}

/* Flexbox adjustments for smaller screens */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .flex-container {
    gap: 10px;
  }
}

/* Responsive Grid Layout */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}



/* Loading Spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 20px;
  height: 20px;
  border: 6px solid #e0e0e0; /* Light border color */
  border-top: 6px solid #202124; /* Dark border color for the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff; /* Neomorphic shadow */
  margin-right: 20px;
}

/* Adjusted Spinner for Loading Button */
.loading-button .button-spinner {
  width: 16px; /* Smaller width */
  height: 16px; /* Smaller height */
  border: 4px solid #e0e0e0; /* Adjusted border width */
  border-top: 4px solid #202124; /* Adjusted border width */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute; /* Position absolute */
  right: 10px; /* Adjust as needed */
}

/* Loading Button Styles */
.loading-button {
  display: inline-flex; /* Use inline-flex to align spinner and text */
  align-items: center; /* Center align spinner and text vertically */
  justify-content: center; /* Center align spinner and text horizontally */
  padding: 8px 16px; /* Adjust padding as needed */
  position: relative; /* Ensure position relative to manage spinner */
  min-width: 220px; /* Set a minimum width to maintain button size */
  height: 36px; /* Set a fixed height */
  box-sizing: border-box; /* Include padding in width/height */
}

/* Prevent button from resizing */
.loading-button:disabled {
  pointer-events: none; /* Prevent clicks on disabled button */
}


.player-spinner {
  width: 40px;
  height: 40px;
  border: 6px solid #e0e0e0; /* Light border color */
  border-top: 6px solid #202124; /* Dark border color for the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff; /* Neomorphic shadow */
  margin-right: 20px;
}


body.dark-mode .spinner {
  border: 6px solid #2c2c2e; /* Dark border color */
  border-top: 6px solid #e8eaed; /* Light border color for the spinner */
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e; /* Neomorphic shadow for dark mode */
}

/* Placeholder Spinner Container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 40px; Adjust based on your layout */
  /* margin-bottom: 20px; */
}




.header-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* padding: 10px; Adjust as needed */
  border-bottom: 0.9px solid #ccc; /* Add bottom border */
}

body.dark-mode .header-container {
  border-bottom: 0.9px solid #444;
}

/* Custom Dropdown Styles */
.actions-dropdown-container {
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  max-width: 250px; 
  padding-top: 20px;
}

.environment-dropdown-container {
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  max-width: 250px; 
 
  justify-content: center;
  display: block;
  margin: 0 auto 20px auto;
}


.landing-dropdown-container {
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  max-width: 150px; 
 
  justify-content: center;
  display: block;
  margin-left: auto;
}

.player-dropdown-container {
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  justify-content: center;
  display: flex;
  margin: 0 auto 20px auto;
  position: relative;
}

.player-search-input {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #202124;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  width: 400px !important; /* Make input take full width */
  max-width: 400px !important; /* Ensure the input doesn't shrink */
  margin: 0 !important; /* Ensure no extra space around the input */
}

body.dark-mode .player-search-input {
  background-color: #2c2e;
  color: #e8eaed;
}

.player-custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}

.player-custom-dropdown-header {
  padding: 8px;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

body.dark-mode .player-custom-dropdown-header {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.player-custom-dropdown-arrow {
  font-size: 18px;
}

.player-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 8px;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  margin-top: 4px;
  z-index: 10;
  font-size: 14px;
  max-height: 400px;
  overflow-y: auto;
}

body.dark-mode .player-dropdown-content {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.player-custom-dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.player-custom-dropdown-option {
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

.player-custom-dropdown-option:hover {
  background-color: #c1c1c1;
}

body.dark-mode .player-custom-dropdown-option:hover {
  background-color: #444444;
}

.player-custom-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #bebebe;
}

body.dark-mode .player-custom-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3e3e3e;
}

/* Style for active player option */
.active-player-option {
  background-color: #d3d3d3 !important; /* Light gray background color */
  color: #333333 !important; /* Dark gray text color */
  font-weight: bold !important; /* Make the text bold */
}

.active-player-option:hover {
  background-color: #a9a9a9 !important; /* Darker gray on hover */
}

body.dark-mode .active-player-option {
  background-color: #555555 !important; /* Dark gray background for dark mode */
  color: #f1f1f1 !important; /* Light gray text color for dark mode */
}

body.dark-mode .active-player-option:hover {
  background-color: #3a3a3a !important; /* Even darker gray on hover in dark mode */
}

.scrollable-players {
  max-height: 400px;
  overflow-y: auto;
}


.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 500px; /* Reduce max-width */

}

.custom-dropdown-header {
  padding: 8px; /* Reduce padding */
  border-radius: 8px; /* Reduce border-radius */
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff; /* Reduce box-shadow */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px; /* Reduce font-size */
}

body.dark-mode .custom-dropdown-header {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e; /* Reduce box-shadow */
}

.custom-dropdown-arrow {
  font-size: 18px; /* Reduce font-size */
}

.custom-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 8px; /* Reduce border-radius */
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff; /* Reduce box-shadow */
  margin-top: 4px; /* Reduce margin-top */
  z-index: 10;
  font-size: 14px; /* Reduce font-size */
  max-height: 250px; /* Set a maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}

body.dark-mode .custom-dropdown-list {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e; /* Reduce box-shadow */
}

.custom-dropdown-option {
  padding: 8px; /* Reduce padding */
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

.custom-dropdown-option:hover {
  background-color: #c1c1c1;
}

body.dark-mode .custom-dropdown-option:hover {
  background-color: #444444;
}

.custom-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #bebebe;
}

body.dark-mode .custom-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3e3e3e;
}

.end-session-option {
  background-color: #d3d3d3 !important; /* Light gray background color */
  color: #333333 !important; /* Dark gray text color */
  font-weight: bold !important; /* Make the text bold */
}

.end-session-option:hover {
  background-color: #a9a9a9 !important; /* Darker gray on hover */
}

body.dark-mode .end-session-option {
  background-color: #555555 !important; /* Dark gray background for dark mode */
  color: #f1f1f1 !important; /* Light gray text color for dark mode */
}

body.dark-mode .end-session-option:hover {
  background-color: #3a3a3a !important; /* Even darker gray on hover in dark mode */
}

.development-option {
  background-color: #d3d3d3 !important; /* Light gray background color */
  color: #333333 !important; /* Dark gray text color */
  font-weight: bold !important; /* Make the text bold */
}

.development-option:hover {
  background-color: #a9a9a9 !important; /* Darker gray on hover */
}

body.dark-mode .development-option {
  background-color: #555555 !important; /* Dark gray background for dark mode */
  color: #f1f1f1 !important; /* Light gray text color for dark mode */
}

body.dark-mode .development-option:hover {
  background-color: #3a3a3a !important; /* Even darker gray on hover in dark mode */
}



.app-card {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Aligns items to the left and right */
  background: #e0e0e0;
   padding: 15px 25px; 
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  margin-bottom: 20px;
  max-width: 500px;
  /* margin: 0 auto; */
}

body.dark-mode .app-card {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.app-icon img {
  width: 70px;
  height: 70px;
  border-radius: 20%;
  margin-right: 20px;
}

.app-details {
  flex-grow: 1.2; /* Allow app details to take up available space */
}

.app-details .app-name {
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
  display: flex;
  margin-left: 25px;
  margin-bottom: 5px;

}

body.dark-mode .app-details .app-name {
  color: #f3f4f6;
}

.app-id {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: #888;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 8px; /* Reduced margin */
  padding: 6px; /* Added padding to make the button smaller */
  display: flex;
  align-items: center;
}

.copy-button img {
  width: 12px;
  height: 12px;
}

.copy-success {
  font-size: 0.8em;
  margin-right: 8px;
}

body .copy-success {
  color: #2a7806;
}

body.dark-mode .copy-success {
  color: #53e111;
}

/* Media Queries for Responsive Design */

/* Styles for devices with a width of 768px or less (tablets and small screens) */
@media (max-width: 768px) {
  .profile-popup {
    width: 80%; /* Adjust the width for smaller screens */
    height: auto; /* Allow height to adjust based on content */
  }

  .header-container {
    flex-direction: column; /* Stack header elements vertically */
    align-items: flex-start; /* Align items to the start */
    gap: 10px; /* Add some gap between items */
  }

  .actions-dropdown-container {
    width: 100%;
    max-width: 100%; /* Allow it to take full width */
  }

  .custom-dropdown {
    width: 100%;
    max-width: 100%; /* Allow it to take full width */
  }

  .custom-dropdown-header {
    padding: 10px; /* Adjust padding */
  }

  .custom-dropdown-list {
    width: 100%; /* Make dropdown list full width */
  }



  .player-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  










  .app-card {
    width: 100%;
    max-width: 100%; /* Allow it to take full width */
    flex-direction: column; /* Stack app details vertically */
    align-items: flex-start; /* Align items to the start */
    padding: 15px; /* Adjust padding */
  }

  .app-header {
    flex-direction: column; /* Stack header elements vertically */
    align-items: center; /* Center the items */
  }

  .logo-container {
    margin-bottom: 10px; /* Add some space below the logo */
  }

  .tabs {
    flex-wrap: wrap; /* Allow tabs to wrap */
    justify-content: center; /* Center the tabs */
  }

  .toggle-switch {
    width: 160px; /* Adjust the width for smaller screens */
    height: 40px; /* Adjust the height for smaller screens */
    flex-direction: column; /* Stack the toggle options vertically */
    justify-content: center; /* Center the options */
  }

  .toggle-switch::after {
    width: 70px; /* Adjust the width of the knob */
    height: 35px; /* Adjust the height of the knob */
  }

  .popup-header {
    flex-direction: column; /* Stack header elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .popup-header h1,h2 {
    margin-bottom: 10px; /* Add space below the title */
    text-align: left; /* Align text to the left */
  }

  .player-card {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
    padding: 10px; /* Adjust padding */
  }

  .player-icon {
    margin-bottom: 10px; /* Add space below the icon */
  }

  .player-info {
    width: 100%; /* Allow player info to take full width */
    text-align: left; /* Align text to the left */
  }

  .player-id {
    margin-top: 10px; /* Add space above the ID */
  }

  .custom-select {
    width: 100%; /* Make select element take full width */
    max-width: 100%; /* Ensure it takes full width */
  }

  .custom-param, .inventory-row {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .custom-param label, .inventory-row label {
    width: 100%; /* Make labels take full width */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 5px; /* Add some space below labels */
  }

  .custom-param input, .inventory-row input, .custom-param select, .inventory-row select {
    width: 100%; /* Make inputs and selects take full width */
    max-width: 100%; /* Ensure they take full width */
  }

  .player-fields {
    width: 100%; /* Make player fields take full width */
    padding: 10px; /* Adjust padding */
  }
}

/* Styles for devices with a width of 480px or less (mobile phones) */
@media (max-width: 480px) {
  .profile-popup {
    width: 90%; /* Adjust the width for smaller screens */
    height: auto; /* Allow height to adjust based on content */
  }

  .header-container {
    flex-direction: column; /* Stack header elements vertically */
    align-items: flex-start; /* Align items to the start */
    gap: 5px; /* Add some gap between items */
  }

  .actions-dropdown-container {
    width: 100%;
    max-width: 100%; /* Allow it to take full width */
  }

  .custom-dropdown {
    width: 100%;
    max-width: 100%; /* Allow it to take full width */
  }

  .custom-dropdown-header {
    padding: 8px; /* Adjust padding */
  }

  .custom-dropdown-list {
    width: 100%; /* Make dropdown list full width */
  }

  .app-card {
    width: 100%;
    max-width: 100%; /* Allow it to take full width */
    flex-direction: column; /* Stack app details vertically */
    align-items: flex-start; /* Align items to the start */
    padding: 10px; /* Adjust padding */
  }

  .app-header {
    flex-direction: column; /* Stack header elements vertically */
    align-items: center; /* Center the items */
  }

  .logo-container {
    margin-bottom: 5px; /* Add some space below the logo */
  }

  .tabs {
    flex-wrap: wrap; /* Allow tabs to wrap */
    justify-content: center; /* Center the tabs */
  }

  .toggle-switch {
    width: 140px; /* Adjust the width for smaller screens */
    height: 35px; /* Adjust the height for smaller screens */
    flex-direction: column; /* Stack the toggle options vertically */
    justify-content: center; /* Center the options */
  }

  .toggle-switch::after {
    width: 60px; /* Adjust the width of the knob */
    height: 30px; /* Adjust the height of the knob */
  }

  .popup-header {
    flex-direction: column; /* Stack header elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .popup-header h1,h2 {
    margin-bottom: 10px; /* Add space below the title */
    text-align: left; /* Align text to the left */
  }

  .player-card {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
    padding: 10px; /* Adjust padding */
  }

  .player-icon {
    margin-bottom: 10px; /* Add space below the icon */
  }

  .player-info {
    width: 100%; /* Allow player info to take full width */
    text-align: left; /* Align text to the left */
  }

  .player-id {
    margin-top: 10px; /* Add space above the ID */
  }

  .custom-select {
    width: 100%; /* Make select element take full width */
    max-width: 100%; /* Ensure it takes full width */
  }

  .custom-param, .inventory-row {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .custom-param label, .inventory-row label {
    width: 100%; /* Make labels take full width */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 5px; /* Add some space below labels */
  }

  .custom-param input, .inventory-row input, .custom-param select, .inventory-row select {
    width: 100%; /* Make inputs and selects take full width */
    max-width: 100%; /* Ensure they take full width */
  }

  .player-fields {
    width: 100%; /* Make player fields take full width */
    padding: 10px; /* Adjust padding */
  }
}
/* Store Styles */
.store-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
}

.store-header {
  text-align: center;
  margin-bottom: 20px;
}

.store-content {
  display: flex;
  height: 500px; /* Adjust the height as needed */
}

.store-categories {
  width: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Make categories independently scrollable */
}

.store-category {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background: #e0e0e0;
  color: #202124;
  border-radius: 12px;
  transition: background 0.3s, box-shadow 0.3s;
}

.store-category.active {
  background: #d4d4d4;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .store-category {
  background: #2c2c2e;
  color: #e8eaed;
}

body.dark-mode .store-category.active {
  background: #404040;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.store-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: calc(100% - 320px); /* Adjust the width calculation */
  overflow-y: auto; /* Make store items independently scrollable */
  padding-left: 30px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.store-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  background: #e0e0e0;
  min-width: 200px;
  min-height: 300px; /* Increase the min-height to accommodate buttons */
  max-height: 400px;
}

.store-item img {
  width: 150px;
  height: 150px;
  border-radius: 8px;
}

.store-item h5 {
  margin: 10px 0;
  text-align: center;
  font-size: large;
 
}

.item-prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: small
}

.item-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.discount {
  color: red;
}

.item-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.buy-button, .custom-purchase-button {
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  width: calc(100% - 20px); /* Ensure buttons take the full width of the card with some padding */
  text-align: center;
}

body.dark-mode .buy-button, body.dark-mode .custom-purchase-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.buy-button:hover, .custom-purchase-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .buy-button:hover, body.dark-mode .custom-purchase-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}




.purchase-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e0e0e0;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  z-index: 1000;
  width: 60%; /* Fixed width */
 height: 60%; /* Fixed height */
  overflow-y: auto;
  transition: background 0.3s, transform 0.2s;
   padding: 20px;
  /* display: flexbox; */
}

body.dark-mode .purchase-popup {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.purchase-popup h2 {
  margin-top: 0px;
}


.purchase-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
  background: inherit; /* Inherit background to blend with popup */
}

.dark-mode .purchase-popup-header {
  border-bottom: 0.5px solid #444; /* Dark mode border color */
  background: rgba(44, 44, 46, 0.8); /* Dark mode background color */
}

.purchase-popup-content label {
  display: block;
  margin: 10px 0;
}
.purchase-popup-content img {
  display: block;
  margin: 10px auto; /* Center-align image */
  max-width: 250px;
  max-height: 250px;
  padding: 20px;
}

.purchase-popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.purchase-popup-content {
  flex: 1;
  padding: 1em;
  overflow-y: auto; /* Enable vertical scrolling */
}

.player-stats-card {
  padding: 20px;
  border-radius: 12px;
  background: #e0e0e0;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  margin: 20px auto;
  max-width: 500px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

body.dark-mode .player-stats-card {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}


.player-stats-card h1 {
  font-size: 20px;
  font-weight: bold;
  color: #202124;
  margin-bottom: 10px;
  text-align: center;
}

.player-overall-card h1 {
  font-size: 20px;
  font-weight: bold;
  color: #202124;
  margin-bottom: 10px;
  text-align: center;
}



.player-stats-card h5 {
  font-size: 12x;
  font-weight: bold;
  color: #202124;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 20px;
  font-weight: lighter;
}

body.dark-mode .player-stats-card h5 {
  color: #e8eaed;
}

body.dark-mode .player-stats-card h3 {
  color: #e8eaed;
}










.player-stats-card h4 {
  font-size: 16px;
  font-weight: bold;
  color: #202124;
  margin-bottom: 10px;
  text-align: left;
}

body.dark-mode .player-stats-card h2 {
  color: #e8eaed;
}

.progress-marker, .currency, .progress-system {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.marker-info, .progress-system, .currency {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.marker-icon, .currency-icon,.system-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
}

.marker-info span, .progress-system span, .currency span {
  font-size: 16px;
  color: #202124;
}

body.dark-mode .marker-info span, 
body.dark-mode .progress-system span, 
body.dark-mode .currency span {
  color: #e8eaed;
}
.player-content-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.player-left-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%; /* Adjust width to fit within the container */
}




.player-stats-card {
  flex: 1;
  max-width: 50%; /* Adjust width to fit within the container */
}

.level-label {
  background-color: #d9e1f2; /* Subtle light blue for light mode */
  padding: 2px 6px; /* Padding for space inside the label */
  margin-left: 5px; /* Space from the text */
  font-weight: bold;
  color: #202124; /* Dark text color */
  border-radius: 4px; /* Slightly rounded corners */
  display: inline-block;
}

body.dark-mode .level-label {
  background-color: #505c6f; /* Subtle dark blue for dark mode */
  color: #e8eaed; /* Light text color for dark mode */
}

.player-profile-card {
  display: flex;
  flex-direction: column;
  background: #e0e0e0;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .player-profile-card {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.player-profile-card-header {
  display: flex;
  align-items: center;
}

.player-profile-card-header h4 {
  font-weight: bold;
  margin-left: 40px;
  font-size: 18px;
}

.player-profile-card label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
}

.player-profile-card input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background: #e0e0e0;
  color: #202124;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}

body.dark-mode .player-profile-card input {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

.player-profile-card .edit-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 0px;
}

.player-profile-card .edit-button img {
  width: 16px;
  height: 16px;
}

body.dark-mode .player-profile-card .edit-button img {
  filter: invert(1);
}

.profile-popup-footer {
  display: flex;
  justify-content: center;
}

.profile-popup-footer .update-button {
  padding: 10px 20px;
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .profile-popup-footer .update-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.profile-popup-footer .update-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .profile-popup-footer .update-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.player-profile-card .generate-button {
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
 
  width: 100px;
}

body.dark-mode .player-profile-card .generate-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.player-profile-card .generate-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .player-profile-card .generate-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.player-profile-card-contents {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the contents within the card */
  width: 100%; /* Ensure it takes full width */
}

.profile-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Keep the labels and inputs left-aligned */
  width: 50%; /* Adjust width as needed to center within the card */
  max-width: 400px; /* Optional: set a max-width to control the input width */
}

.profile-label {
  margin-bottom: 4px;
  font-weight: bold;
}

.global-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.loading-logo {
  width: 100px; /* Adjust size as needed */
  height: auto;
  animation: spinAndPulse 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.3);
  }
}

@keyframes spinAndPulse {
  0%, 100% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
  50% {
    opacity: 0.5;
    transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.view-session-logs-button-game {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  color: #202124;
  border: none;
  border-radius: 30px; /* Increased border-radius for pill shape */
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  font-size: 16px;
  transition: width 0.3s, background 0.3s, transform 0.2s, box-shadow 0.2s; /* Added width transition */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensure it stays on top of modals */
  width: 44px; /* Initial width */
  overflow: hidden; /* Hide the text initially */
}

body.dark-mode .view-session-logs-button-game {
  background: linear-gradient(145deg, #3a3a3a, #1e1e1e);
  color: #e8eaed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(255, 255, 255, 0.1);
}

.view-session-logs-button-game:hover {
  width: 150px; /* Expanded width */
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(255, 255, 255, 0.8);
  background: linear-gradient(145deg, #fafafa, #e0e0e0);
}

body.dark-mode .view-session-logs-button-game:hover {
  width: 150px; /* Expanded width */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5), 0 6px 12px rgba(255, 255, 255, 0.2);
  background: linear-gradient(145deg, #4a4a4a, #2e2e2e);
}



.view-session-logs-button-game:hover .log-icon{
  position: unset;
}


.view-session-logs-button-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  color: #202124;
  border: none;
  border-radius: 30px; /* Increased border-radius for pill shape */
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  font-size: 16px;
  transition: width 0.3s, background 0.3s, transform 0.2s, box-shadow 0.2s; /* Added width transition */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensure it stays on top of modals */
  width: 44px; /* Initial width */
  overflow: hidden; /* Hide the text initially */
}

body.dark-mode .view-session-logs-button-fixed {
  background: linear-gradient(145deg, #3a3a3a, #1e1e1e);
  color: #e8eaed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(255, 255, 255, 0.1);
}

.view-session-logs-button-fixed:hover {
  width: 150px; /* Expanded width */
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(255, 255, 255, 0.8);
  background: linear-gradient(145deg, #fafafa, #e0e0e0);
}

body.dark-mode .view-session-logs-button-fixed:hover {
  width: 150px; /* Expanded width */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5), 0 6px 12px rgba(255, 255, 255, 0.2);
  background: linear-gradient(145deg, #4a4a4a, #2e2e2e);
}



.view-session-logs-button-fixed:hover .log-icon{
  position: unset;
}

.log-icon {
  position: absolute;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  transition: margin-right 0.3s; /* Transition for the margin */
}

body.dark-mode .log-icon {
  filter: invert(1);
}


.button-text {
  opacity: 0; /* Initially hidden */
  white-space: nowrap; /* Prevent text from wrapping */
  transition: opacity 0.3s, margin-left 0.3s; /* Transition for opacity and margin */
  margin-left: 0; /* Initially no margin */
}

.view-session-logs-button-game:hover .button-text {
  opacity: 1; /* Show text on hover */
  margin-left: 8px; /* Margin to make space for text */
}

.button-text {
  opacity: 0; /* Initially hidden */
  white-space: nowrap; /* Prevent text from wrapping */
  transition: opacity 0.3s, margin-left 0.3s; /* Transition for opacity and margin */
  margin-left: 0; /* Initially no margin */
}

.view-session-logs-button-fixed:hover .button-text {
  opacity: 1; /* Show text on hover */
  margin-left: 8px; /* Margin to make space for text */
}


.user-info {
  text-align: left;
  padding: 20px;
  background: #e0e0e0; /* Use the same color as other cards */
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

body.dark-mode .user-info {
  background: #2c2c2e; /* Use the same color as other cards */
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.user-info p {
  margin: 0;
  font-size: 14px;
  padding-left: 12px;
}

.user-info h1 {
  margin: 0;
  font-size: 18px;
}

.capitalize {
  text-transform: capitalize;
}



/* Breadcrumbs Styles */
.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: center; /* Change this to center the breadcrumbs */
  margin: 10px 0;
  padding: 10px;
}

.breadcrumb {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  color: #202124;
  font-weight: 500;
  transition: background 0.3s, color 0.3s;
}

.breadcrumb.active {
  color: #4285f4; /* Highlight color for the active breadcrumb */
  font-weight: bold;
}

.breadcrumb:hover {
  color: #202124;
}

.breadcrumb-divider {
  color: #202124;
  font-weight: bold;
}

body.dark-mode .breadcrumb {
  color: #e8eaed;
}

body.dark-mode .breadcrumb.active {
  color: #8ab4f8; /* Highlight color for the active breadcrumb in dark mode */
}

body.dark-mode .breadcrumb:hover {
  color: #e8eaed;
}

body.dark-mode .breadcrumb-divider {
  color: #e8eaed;
}



/* Toast Styles */
.neu-toast {
  background: #e0e0e0 !important;
  border-radius: 12px !important;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff !important;
  color: #202124 !important;
  font-family: 'Inter', sans-serif !important;
  padding: 10px !important;
  font-size: 14px;
  text-align: left;
  
}

body.dark-mode .neu-toast {
  background: #2c2c2e !important;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e !important;
  color: #e8eaed !important;
}

.neu-toast-progress {
  background: #202124 !important;
}

body.dark-mode .neu-toast-progress {
  background: #e8eaed !important;
}

.neu-toast-close {
color: #000000;
  cursor: pointer; /* Add this line */
}

body.dark-mode .neu-toast-close {
 color: #ffffff !important;
  cursor: pointer; /* Add this line */
  
}



/*------ Settings ------*/
.fullscreen-container {
  --color-light: #000000; /* Black for light mode */
  --color-dark: #ffffff; /* White for dark mode */
  --size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: var(--size);
  user-select: none;
  fill: var(--color-light);
  background: none; /* Remove background */
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  padding: 0; /* Remove padding */
  margin-right: 24px; /* Remove margin */

}

body.dark-mode .fullscreen-container {
  fill: var(--color-dark);
}

.fullscreen-container .fullscreen {
  position: absolute;
  animation: keyframes-fill .5s;
}

.fullscreen-container .exitfullscreen {
  position: absolute;
  display: none;
  animation: keyframes-fill .5s;
}

/* ------ On check event ------ */
.fullscreen-container input:checked ~ .fullscreen {
  display: none;
}

.fullscreen-container input:checked ~ .exitfullscreen {
  display: block;
}

/* ------ Hide the default checkbox ------ */
.fullscreen-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* ------ Animation ------ */
@keyframes keyframes-fill {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
  }
}



.master-player-container{
  padding-bottom: 20px;
}


.match-select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
}

.match-select-dropdown-header {
  padding: 8px;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

body.dark-mode .match-select-dropdown-header {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.match-select-dropdown-arrow {
  font-size: 18px;
}

.match-select-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 8px;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  margin-top: 4px;
  z-index: 10;
  font-size: 14px;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
}

body.dark-mode .match-select-dropdown-list {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.match-select-dropdown-option {
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

.match-select-dropdown-option:hover {
  background-color: #c1c1c1;
}

body.dark-mode .match-select-dropdown-option:hover {
  background-color: #444444;
}

.match-select-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #bebebe;
}

body.dark-mode .match-select-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3e3e3e;
}


.centered-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Keep the labels and inputs left-aligned */
  width: 100%; /* Adjust width as needed to center within the card */
  max-width: 400px; /* Optional: set a max-width to control the input width */
  margin-left: 100px;
}


.centered-contents {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the contents within the card */
  width: 100%; /* Ensure it takes full width */
}



.param-select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 150px;
 

}

.param-select-dropdown-header {
  padding: 4px;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

body.dark-mode .param-select-dropdown-header {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.param-select-dropdown-arrow {
  font-size: 18px;
}

.param-select-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 8px;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  margin-top: 4px;
  z-index: 10;
  font-size: 14px;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
}

body.dark-mode .param-select-dropdown-list {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.param-select-dropdown-option {
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

.param-select-dropdown-option:hover {
  background-color: #c1c1c1;
}

body.dark-mode .param-select-dropdown-option:hover {
  background-color: #444444;
}

.param-select-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #bebebe;
}

body.dark-mode .param-select-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3e3e3e;
}

.info-tip {
  display: flex;
  align-items: center;
  border-left: 4px solid #007BFF;
  padding-left: 10px;
  margin: 10px auto;
  color: #202124;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  max-width: 500px;
}

body.dark-mode .info-tip {
  color: #e8eaed;
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}


.results-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #e0e0e0;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  transition: background-color 0.3s, box-shadow 0.3s;
}

body.dark-mode .results-container {
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.results-container h2 {
  text-align: center;
  color: #202124;
}

body.dark-mode .results-container h2 {
  color: #e8eaed;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  color: #202124;
}

body.dark-mode .results-table {
  color: #e8eaed;
}

.results-table th,
.results-table td {
  padding: 12px;
  text-align: left;
  background-color: #e0e0e0;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}

body.dark-mode .results-table th,
body.dark-mode .results-table td {
  background-color: #2c2c2e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

.results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

body.dark-mode .results-table th {
  background-color: #333;
}

.results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

body.dark-mode .results-table tr:nth-child(even) {
  background-color: #444;
}

.results-table tr:hover {
  background-color: #f1f1f1;
}

body.dark-mode .results-table tr:hover {
  background-color: #555;
}

.rank-icon {
  font-size: 1.5em;
  color: #ffbf00; /* Gold color for crown icon */
}

.rank-1 {
  background-color: #fffbea; /* Light yellow background for first rank */
  border-left: 5px solid #ffbf00; /* Gold border for first rank */
}

.rank-2 {
  background-color: #e0e0e0; /* Light gray background for second rank */
  border-left: 5px solid #c0c0c0; /* Silver border for second rank */
}

.rank-3 {
  background-color: #f0f0f0; /* Lighter gray background for third rank */
  border-left: 5px solid #cd7f32; /* Bronze border for third rank */
}

body.dark-mode .rank-1 {
  background-color: #4a4a44; /* Dark yellow background for first rank in dark mode */
}

body.dark-mode .rank-2 {
  background-color: #3a3a3a; /* Dark gray background for second rank in dark mode */
}

body.dark-mode .rank-3 {
  background-color: #5a5a5a; /* Darker gray background for third rank in dark mode */
}








.no-results-image {
  width: 150px;
  height: 150px;

  filter: invert(1); /* Invert colors for light mode */
}

body.dark-mode .no-results-image {
  filter: invert(0); /* Original colors for dark mode */
}

.no-results-text {
  font-size: 18px;
  color: #202124;
}

body.dark-mode .no-results-text {
  color: #e8eaed;
}



.bored-button {
    position: fixed;
    bottom: 80px;
   right: 20px;
    background: linear-gradient(145deg, #f0f0f0, #cacaca);
    color: #202124;
    border: none;
    border-radius: 60px; /* Increased border-radius for pill shape */
    padding: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    font-size: 16px;
    transition: width 0.3s, background 0.3s, transform 0.2s, box-shadow 0.2s; /* Added width transition */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; /* Ensure it stays on top of modals */
    width: 44px; /* Initial width */
    height: 44px;
    overflow: hidden; /* Hide the text initially */
  }
  
  body.dark-mode .bored-button{
    background: linear-gradient(145deg, #3a3a3a, #1e1e1e);
    color: #e8eaed;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(255, 255, 255, 0.1);
  }

.snake-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  
}

.snake-modal-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  position: relative;


}

body.dark-mode .snake-modal-content {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.close-snake-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}


/* Snake Game Container */
.snake-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  width: 80%;
  margin: 0 auto;
}

body.dark-mode .snake-game-container {
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

/* Game Title and Info */
.snake-game-container h1 {
  font-size: 24px;
  color: #202124;
}

body.dark-mode .snake-game-container h1 {
  color: #e8eaed;
}

.snake-game-container p {
  font-size: 16px;
  color: #202124;
  margin: 5px 0;
}

body.dark-mode .snake-game-container p {
  color: #e8eaed;
}

.canvas-container {
  position: relative;
  width: 400px; /* Ensure this matches your canvas size */
  height: 400px; /* Ensure this matches your canvas size */
  margin: 0 auto; /* Center the container */
  background: #e0e0e0; /* Light mode background */
  box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
  transition: box-shadow 0.3s;
  border-radius: 0; /* Remove rounded corners */
}

body.dark-mode .canvas-container {
  background: #2c2c2e; /* Dark mode background */
  box-shadow: 8px 8px 16px #1a1a1a, -8px -8px 16px #3e3e3e;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
  border: none; /* Remove canvas border to allow container's shadow */
  outline: none;
}


/* Snake Styling */
.snake-segment {
  background-color: #00bcd4; /* Sci-fi cyan */
  border-radius: 4px;
  box-shadow: inset 2px 2px 4px #008394, inset -2px -2px 4px #4dd0e1;
}

body.dark-mode .snake-segment {
  box-shadow: inset 2px 2px 4px #005662, inset -2px -2px 4px #26c6da;
}

/* Food Styling */
.food {
  background-color: #ff9800; /* Sci-fi orange */
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

body.dark-mode .food {
  background-color: #ffb74d;
}

/* Game Over Text */
.game-over {
  font-size: 18px;
  color: #ff5722;
  margin-top: 20px;
  text-align: center;
}

body.dark-mode .game-over {
  color: #ff8a65;
}

/* Buttons */
.snake-game-container button {
 

  border: none;
  border-radius: 12px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.2s, transform 0.2s;
}

body.dark-mode .snake-game-container button {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.snake-game-container button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .snake-game-container button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

/* Instructions Modal */
.snake-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.snake-info-modal-content {
  background: #e0e0e0;
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  color: #202124;
  text-align: left;
}

body.dark-mode .snake-info-modal-content {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
  color: #e8eaed;
}

.snake-info-modal-content h2 {
  margin-top: 0;
  text-align: center;
}

.snake-info-modal-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

.snake-info-modal-content button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.2s, transform 0.2s;
}

body.dark-mode .snake-info-modal-content button {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.snake-info-modal-content button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .snake-info-modal-content button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}



.canvas-container {
  position: relative;
  width: 400px; /* Ensure this matches your canvas size */
  height: 400px; /* Ensure this matches your canvas size */
  margin: 0 auto; /* Center the container */
  background: #e0e0e0; /* Light mode background */
  box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
  transition: box-shadow 0.3s;
  border-radius: 0; /* Remove rounded corners */
}

body.dark-mode .canvas-container {
  background: #2c2c2e; /* Dark mode background */
  box-shadow: 8px 8px 16px #1a1a1a, -8px -8px 16px #3e3e3e;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
  border: none; /* Remove canvas border to allow container's shadow */
  outline: none;
}


/* Neomorphic Buttons */
.snake-game-button {
  padding: 10px 20px;
  border-radius: 12px;
  background-color: #e0e0e0;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  transition: background 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.snake-game-button:hover {
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .snake-game-button {
  background-color: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

body.dark-mode .snake-game-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.snake-score{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.snake-header{
  display: flex;
  align-items: center;
  justify-content: center;

}






/* Auth Container */
.auth-container {
  width: 350px;
  max-width: 100%;
 height: 400px;
 max-height: 100%;
  margin: 100px auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  backdrop-filter: blur(10px);
}

body.dark-mode .auth-container {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.auth-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #202124;
}

body.dark-mode .auth-container h2 {
  color: #e8eaed;
}

.auth-container input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  color: #202124;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
  transition: all 0.2s ease;
}

body.dark-mode .auth-container input {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

.auth-container button {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #202124;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .auth-container button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.auth-container button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .auth-container button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.auth-container p {
  margin-top: 20px;
  color: #2196f3;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

body.dark-mode .auth-container p {
  color: #8ab4f8;
}

.auth-container p:hover {
  text-decoration: underline;
}

/* Snake Game Home */
.home-screen {
  width: 350px;
  max-width: 100%;
  height: 400px;
  max-height: 100%;
  margin: 100px auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  backdrop-filter: blur(10px);
  text-align: center;
}

body.dark-mode .home-screen {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.home-screen img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.home-screen h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #202124;
}

body.dark-mode .home-screen h1 {
  color: #e8eaed;
}

.home-screen .begin-button,
.home-screen .how-to-play-button {
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.1);
  color: #202124;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

body.dark-mode .home-screen .begin-button,
body.dark-mode .home-screen .how-to-play-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.home-screen .begin-button:hover,
.home-screen .how-to-play-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .home-screen .begin-button:hover,
body.dark-mode .home-screen .how-to-play-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}
/* How-to-Play Modal */
.how-to-play-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  z-index: 1000;
  width: 150%;
  max-width: 600px;
  height: auto;
  max-height: 60vh; /* Ensures content does not exceed viewport height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: background 0.3s, transform 0.2s, width 0.5s ease, height 0.5s ease;
}

body.dark-mode .how-to-play-modal {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

/* Adjust header to stay sticky at the top */
.how-to-play-modal .modal-content {
  flex: 1;

  overflow-y: auto; /* Enable vertical scrolling */
}

.how-to-play-modal .modal-content h2 {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
}

.how-to-play-modal .modal-content p {

  font-size: 0.9rem;

}






.how-to-play-modal .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  border-bottom: 0.5px solid #ccc;

}

body.dark-mode .how-to-play-modal .popup-header {
  border-bottom: 0.5px solid #444;
}

.how-to-play-modal .popup-header h1 {
  margin: 0;
  font-size: 18px;
}

.how-to-play-modal .modal-content {
  padding: 1em;

}

.how-to-play-modal .how-to-play-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 10px;

}

.how-to-play-modal .icon {
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%);
  transition: filter 0.3s;
}

body.dark-mode .how-to-play-modal .icon {
  filter: invert(1) brightness(2);
}







/* How-to-Play Modal */
.leaderboard-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  z-index: 1000;
  width: 150%;
  max-width: 600px;
height: 800px;
  max-height: 80vh; /* Ensures content does not exceed viewport height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: background 0.3s, transform 0.2s, width 0.5s ease, height 0.5s ease;
}

body.dark-mode .leaderboard-modal {
  background: rgba(44, 44, 46, 0.8);
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

/* Adjust header to stay sticky at the top */
.leaderboard-modal .leaderboard-modal-content {
  flex: 1;
padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
}

.leaderboard-modal  .leaderboard-modal-content h2 {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
}


.leaderboard-modal  .leaderboard-modal-content p {

  font-size: 0.9rem;

}






.leaderboard-modal  .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  border-bottom: 0.5px solid #ccc;

}

body.dark-mode .leaderboard-modal  .popup-header {
  border-bottom: 0.5px solid #444;
}

.leaderboard-modal .popup-header h1 {
  margin: 0;
  font-size: 18px;
}

.leaderboard-modal  .modal-content {
  padding: 1em;

}


/* Organization Popup Styling */
.org-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.org-popup-content {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px; /* Reduced width for a smaller popup */
  width: 90%;
  text-align: center;
  box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff; /* Subtle neomorphic shadow */
}

body.dark-mode .org-popup-content {
  background-color: #2c2c2e;
  box-shadow: 2px 2px 6px #1a1a1a, -2px -2px 6px #3e3e3e;
}

.org-popup-header {
  margin-bottom: 15px;
}

.org-popup-header h2 {
  margin: 0;
  font-size: 20px;
  color: #202124;
}

body.dark-mode .org-popup-header h2 {
  color: #e8eaed;
}

.org-list {
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}

.org-item {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin-bottom: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s, transform 0.1s;
  box-shadow: inset 2px 2px 4px #ffffff, inset -2px -2px 4px #bebebe; /* Neomorphic inset shadow */
}

body.dark-mode .org-item {
  background-color: #2c2c2e;
  box-shadow: inset 2px 2px 4px #3e3e3e, inset -2px -2px 4px #1a1a1a;
}

.org-item:hover {
  background-color: #dcdcdc;
  transform: translateY(-1px);
}

body.dark-mode .org-item:hover {
  background-color: #3a3a3c;
}

.org-item.selected {
  background-color: #d4d4d4;
  box-shadow: inset 2px 2px 4px #bebebe, inset -2px -2px 4px #ffffff;
}

body.dark-mode .org-item.selected {
  background-color: #404040;
  box-shadow: inset 2px 2px 4px #1a1a1a, inset -2px -2px 4px #3e3e3e;
}

.org-item img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: cover;
}

.org-name {
  font-size: 16px;
  color: #202124;
}

body.dark-mode .org-name {
  color: #e8eaed;
}

.continue-button {
  padding: 10px 20px;
  background-color: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff; /* Neomorphic shadow */
}

body.dark-mode .continue-button {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.continue-button:hover {
  background-color: #dcdcdc;
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .continue-button:hover {
  background-color: #3a3a3c;
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

/* CSV Upload Section */
.csv-upload-section {
  margin-top: 20px;
  text-align: left;
  max-width: 500px; /* Increased width to accommodate more buttons */
  margin-left: auto;
  margin-right: auto;
}

.csv-upload-section label {
  font-size: 14px;
  font-weight: bold;
  color: #5f6368;
  display: block;
  margin-bottom: 8px;
}

.csv-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
}

/* Custom Upload Button */
.csv-upload-label {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 12px;
  background: #e0e0e0;
  color: #202124;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

.csv-upload-label:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

/* Download buttons - matching the upload style */
.download-sample-btn,
.download-all-btn {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 12px;
  background: #e0e0e0;
  color: #202124;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.download-sample-btn:hover,
.download-all-btn:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

/* Special styling for the download all button */
.download-all-btn {
  background: #f0f0f0;
}

/* CSV Format Info */
.csv-format-info {
  font-size: 12px;
  color: #5f6368;
  margin-top: 8px;
  text-align: center;
}

/* Dark Mode */
body.dark-mode .csv-upload-label,
body.dark-mode .download-sample-btn,
body.dark-mode .download-all-btn {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

body.dark-mode .csv-upload-label:hover,
body.dark-mode .download-sample-btn:hover,
body.dark-mode .download-all-btn:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

body.dark-mode .download-all-btn {
  background: #3c3c3e;
}

body.dark-mode .csv-format-info {
  color: #9aa0a6;
}

/* Hide the default file input */
.csv-upload-section input[type="file"] {
  display: none;
}
.session-log-viewer {
  background: #e0e0e0;
 padding-left: 10px;
 padding-right: 10px;
 padding-bottom: 10px;
  border-radius: 12px;
  width: 80vw; /* Width relative to viewport */
  height: 80vh; /* Height relative to viewport */
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  resize: both;
  overflow: auto;
  pointer-events: all; /* Enable interaction for the log viewer */
}



.session-log-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensure it stays above other elements */
  pointer-events: none; /* Allow clicks to pass through unless specifically overridden */
  
}



.session-log-viewer {
  min-width: 675px;  /* Add this line */
  min-height: 100px; /* Add this line */
  /* existing styles */
}



body.dark-mode .session-log-viewer {
  background: #2c2c2e;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.session-log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 40px;
  cursor: move;

}


.react-resizable-handle {
  z-index: 10001;
}

.session-log-title {
  font-size: 13px;
  font-weight: bold;
  flex-grow: 1; /* Allow the title to take up remaining space */
  text-align: center; /* Center align the title text */
}



body.dark-mode .session-log-player-name {
  color: #e8eaed;
}




.session-close-button {
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;

  margin-right: auto; /* Ensure the button is pushed to the far left */
}


body.dark-mode .close-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.close-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .close-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.log-entries {
  overflow-y: auto;
  font-family:  Courier, monospace;
  font-size: 12px;
  color: #333;
  flex-grow: 1;
  background: #000;
  padding: 10px;
  border-radius: 8px;
  box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
}



body.dark-mode .log-entries {
  color: #e8eaed;
  background: #1e1e1e;
  box-shadow: inset 4px 4px 8px #1a1a1a, inset -4px -4px 8px #3e3e3e;
}

body:not(.dark-mode) .log-entries {
  background: #f5f5f5;
}

.log-entry {
  margin-bottom: 10px;
  white-space: pre-wrap;
  text-align: left;
}

.log-entry-header.with-checkbox {
  display: grid;
  grid-template-columns: auto 2fr 1fr 3fr 1fr 2fr 2fr auto;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.log-entry-header.without-checkbox {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr 1fr 2fr 2fr auto;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}



.log-entry-header div {
  text-align: left; /* Align text to the left */
}



.log-entry-details {
  margin-top: 5px;
  display: none;
}

.log-entry-header .status {
  color: #4caf50;
}

.log-entry-header .status.error {
  color: #f44336;
}

.expand-icon {
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
}

.expand-all-button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Add space between buttons */
  margin-left: auto; /* Ensure the container is pushed to the far right */
}

.expand-all-button {
  background: #e0e0e0;
  color: #202124;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px 10px;
  
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
  font-size: 12px;
}

body.dark-mode .expand-all-button {
  background: #2c2c2e;
  color: #e8eaed;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

.expand-all-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .expand-all-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.refresh-button {
  background: #e0e0e0;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

body.dark-mode .refresh-button {
  background: #2c2c2e;
}

.refresh-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .refresh-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}

.refresh-icon {
  width: 12px; /* Adjust size as needed */
  height: 12px; /* Adjust size as needed */
}

body.dark-mode .refresh-icon {
  filter: invert(1);
}

.log-entry-section-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.log-entry-section-header .expand-icon {
  margin-right: 5px;
}

.log-copy-icon {
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;
}

body .log-copy-success {
  font-size: 0.8em;
  margin-right: 8px;
  color: #2a7806;
  padding: 3px;
}

body.dark-mode .log-copy-success {
  color: #53e111;
}


.response-time-excellent {
  color: #4caf50; /* Green */
}

.response-time-good {
  color: #2196f3; /* Blue */
}

.response-time-average {
  color: #ff9800; /* Orange */
}

.response-time-poor {
  color: #f44336; /* Red */
}

.status-success {
  color: #4caf50; /* Green for success status codes like 2xx */
}

.status-error {
  color: #f44336; /* Red for error status codes like 4xx and 5xx */
}

.method-post {
  color: #2196f3; /* Blue for POST method */
}

.method-get {
  color: #4caf50; /* Green for GET method */
}

.method-put {
  color: #ff9800; /* Orange for PUT method */
}

.method-delete {
  color: #f44336; /* Red for DELETE method */
}


.draggable-edge {
  position: absolute;
  background: transparent;
  cursor: move; /* Change cursor to move (cross) */
}

.draggable-left, .draggable-right {
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: ew-resize;
}

.draggable-left {
  left: 0;
}

.draggable-right {
  right: 0;
}

.draggable-bottom {
  left: 0;
  right: 0;
  height: 10px;
  bottom: 0;
  cursor: ns-resize;
}



.socket-toggle-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right:10px; /* Adjust space between other elements and the toggle */
  
}

.socket-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.socket-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.socket-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.socket-toggle-switch input:checked + .socket-slider {
  background-color: #2196F3;
}

.socket-toggle-switch input:checked + .socket-slider:before {
  transform: translateX(14px);
}

.socket-toggle-switch:hover .hover-label {
  visibility: visible;
  opacity: 1;
}


.hover-label {
  visibility: hidden;
  opacity: 0;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 145%; /* Position below the toggle switch */
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  font-size: 12px; /* Matching the font size of your system */
  white-space: nowrap; /* Prevent text from wrapping */
}

.hover-label::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Dark mode hover label */
body.dark-mode .hover-label {
  background-color: #333;
  color: #e8eaed;
}

body.dark-mode .hover-label::after {
  border-color: #333 transparent transparent transparent;
}


/* Enhanced pulsing glow effect for log entries container */
@keyframes subtlePulseGlow {
  0% {
    box-shadow: 0 0 3px 0 rgba(0, 255, 0, 0.15);
  }
  50% {
    box-shadow: 0 0 5px 1px rgba(0, 255, 0, 0.25);
  }
  100% {
    box-shadow: 0 0 3px 0 rgba(0, 255, 0, 0.15);
  }
}

.log-entries.real-time {
  animation: subtlePulseGlow 2s infinite;
  transition: animation 0.3s ease;
}

/* Dark mode adjustments */
body.dark-mode .log-entries.real-time {
  animation: subtlePulseGlow 2s infinite;
}

/* Enhanced fade-in effect for new log entries */
@keyframes fadeIn {
  0% {
    background-color: rgba(0, 255, 0, 0.25);
  }
  100% {
    background-color: transparent;
  }
}

.log-entry.new {
  animation: fadeIn 1s ease;
}

/* Add these styles to your existing CSS file */

.selection-mode-toggle {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.selection-mode-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.selection-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.selection-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.selection-mode-toggle input:checked + .selection-slider {
  background-color: #2196F3;
}

.selection-mode-toggle input:checked + .selection-slider:before {
  transform: translateX(14px);
}



body.dark-mode .selection-mode-toggle input:checked + .selection-slider {
  background-color: #1a73e8;
}

.send-to-slack-button {
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff;
}

.send-to-slack-button:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
}

body.dark-mode .send-to-slack-button {
  background: #357a38;
  box-shadow: 4px 4px 8px #1a1a1a, -4px -4px 8px #3e3e3e;
}

body.dark-mode .send-to-slack-button:hover {
  box-shadow: 2px 2px 4px #1a1a1a, -2px -2px 4px #3e3e3e;
}


.log-select-checkbox {
  appearance: none;
  background-color: #2c2c2e; /* Match the dark mode background */
  border: 1px solid #555;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.log-select-checkbox:checked {
  background-color: #2196F3; /* Highlight color for selected */
  border-color: #2196F3;
}

body:not(.dark-mode) .log-select-checkbox {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
}

.log-select-checkbox:checked::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  margin: 2px;
  background-color: #fff; /* Checkmark color */
}



.slack-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 150px;
  margin-right: 10px;
 
}

.slack-dropdown-header {
  padding: 8px;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #202124;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  height: 8px;
}

body.dark-mode .slack-dropdown-header {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.slack-dropdown-arrow {
  font-size: 18px;
}

.slack-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #e0e0e0;
  color: #202124;
  border-radius: 8px;
  box-shadow: 3px 3px 6px #bebebe, -3px -3px 6px #ffffff;
  margin-top: 4px;
  z-index: 10;
  font-size: 14px;
  max-height: 200px;
  overflow-y: auto;
}

body.dark-mode .slack-dropdown-list {
  background-color: #2c2c2e;
  color: #e8eaed;
  box-shadow: 3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e;
}

.slack-dropdown-option {
  padding: 8px ;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  height: 8px;
  font-size: 10px;
}

.slack-dropdown-option:hover {
  background-color: #c1c1c1;
}

body.dark-mode .slack-dropdown-option:hover {
  background-color: #444444;
}

.slack-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #bebebe;
}

body.dark-mode .slack-dropdown-option:not(:last-child) {
  border-bottom: 1px solid #3e3e3e;
}


.selection-mode-toggle:hover .hover-label {
  visibility: visible;
  opacity: 1;
}


.slack-dropdown input[type="text"] {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #202124;
  width: 100%;
  font-size: 12px;
  outline: none;
  box-sizing: border-box;
  position: absolute;
  top: 0; /* Align perfectly at the top */
  left: 0;
  z-index: 1;
  margin: 0; /* Ensure no extra space at the top */
}

body.dark-mode .slack-dropdown input[type="text"] {
  background-color: #2c2c2e;
  color: #e8eaed;
}

@keyframes loader_5191 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.loader {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader_sq1 {
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#loader_sq2 {
  top: calc(50% - 20px);
  left: 50%;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#loader_sq3 {
  top: calc(50% - 20px);
  left: calc(50% + 20px);
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#loader_sq4 {
  top: 50%;
  left: calc(50% - 20px);
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#loader_sq5 {
  top: 50%;
  left: 50%;
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#loader_sq6 {
  top: 50%;
  left: calc(50% + 20px);
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#loader_sq7 {
  top: calc(50% + 20px);
  left: calc(50% - 20px);
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#loader_sq8 {
  top: calc(50% + 20px);
  left: 50%;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#loader_sq9 {
  top: calc(50% + 20px);
  left: calc(50% + 20px);
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}
